import { useEffect, useState } from 'react';

import BasicInfoProfileDetails from './BasicInfo/Details';
import WorkHistory from './JobHistory';
import SkillsAndTagsInfo from './SkillsAndTags';
import CandidateAvailability from './Availability';
import CandidateEducation from './Education';
import Loader from "react-loader-spinner";
import { Grid } from '@material-ui/core';

import CandidateRootProfileStore from './Stores';

function InitiateProfilePage(): any{
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        CandidateRootProfileStore.buildCandidateProfile().then(() => {
            setLoading(false);
        });
    }, []);

    if(isLoading) {
        return (
            <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        );
    } else {
        return (
            <>
                <section><BasicInfoProfileDetails /></section>
                <div className="tabContentHolder"></div>
    
                <Grid container spacing={2} className="">
                    <Grid item xs={7}><section><WorkHistory /></section></Grid>
                    <Grid item xs={5}><section><CandidateEducation /></section></Grid>
                </Grid>
                <Grid container spacing={2} className="">
                    <Grid item xs={7}><section><SkillsAndTagsInfo /></section></Grid>
                    <Grid item xs={5}><section><CandidateAvailability /></section></Grid>
                </Grid>
            </>
        );
    }
}

const initiateProfilePage = InitiateProfilePage;
export default initiateProfilePage;