import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CandidateEducationStore from '../Store';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface IProps {}

inject('CandidateEducationStore')
@observer
class CandidateProfileAddEducation extends Component<IProps, any>{
    candidateEducationStore = CandidateEducationStore;
    record: any;
    errors: any = {
        establishmentError:  ''
      };
    constructor(props: any){
        super(props);
        this.state = { open: false};
    }

    render(){
        const { formSubmitError, formSubmitNewEducationRecord } = this.candidateEducationStore;
        
        const setEstablishment = (title: string) => {
            if(title.length < 1){
              formSubmitError.establishmentError = 'Please Insert a Job Title';
              this.errors.establishmentError = 'Please Insert a Job Title';
            }
            else {
              formSubmitError.establishmentError = '';
              formSubmitNewEducationRecord.establishmentName = title;
            }
          }

        const handleEducationSubmit = async () => {
    
            await this.candidateEducationStore.addEducation(formSubmitNewEducationRecord);
      
            // Update state to close modal or set store isLoading to true
            this.setState({ open: false})
      
          }

        const dialogButton = (
            <IconButton aria-label="Add Education" size="small" onClick={() => this.setState({ open: true})}>
              <AddCircleIcon fontSize="inherit" />
            </IconButton>
        );

        const dialogContent = (
            <Dialog open={this.state.open} onClose={() => this.setState({ open: false})} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Add</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Add your Education details below
                </DialogContentText>
                <form onSubmit={() => handleEducationSubmit}>
                <TextField margin="dense" id="title" label="School/College Name" onChange={e => setEstablishment(e.target.value)} type="text" placeholder="School/College Name" fullWidth required />
                <p className="">{formSubmitError.establishmentError}</p>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({ open: false})} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleEducationSubmit} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          )

        return (
            <div className="workExperienceAddHold">
                <div className="weahTop">{dialogButton}</div>
                <div className="weahMid">{dialogContent}</div>
            </div>
        );
    
    }
}

export default CandidateProfileAddEducation;