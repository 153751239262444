import { Component } from 'react';
import { inject } from 'mobx-react';
import Helmet from 'react-helmet';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './style';

import Container from '@material-ui/core/Container';

import CandidateRootProfileStore from './Stores';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import InitiateProfilePage from './init';

interface IProps extends WithStyles<typeof styles> {}

inject('CandidateRootProfileStore')
class CandidateProfile extends Component<IProps> {
    candidateRootProfileStore = CandidateRootProfileStore;
    content: any;

    render() {

        // const { classes } = this.props;
        //const { isLoadingCandidateProfile, isBuilding } = this.candidateRootProfileStore;

        return(
            <div className="CandidateProfile">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Trickell Hire | Profile</title>
                </Helmet>
                <Container>
                    <InitiateProfilePage />
                </Container>
            </div>
        )
    }
}

export default withStyles(styles)(CandidateProfile);