import { makeObservable, observable, action, autorun } from "mobx";
import api from "../../../../../Utils/Api";

class CandidateSearchStore {
    @observable searchDetails: any = {
        job: '',
        location: '',
        radius: ''
    };
    @observable hasLocationResults: boolean = false;
    @observable searchResults: any;
    @observable isError: boolean = false;
    @observable isLoading: boolean = false;
    
    constructor(){
        makeObservable(this);
        autorun(() => {
            // this.getWorkHistory();
        });
    }

    @action.bound
    async getSearchResults(searchTerm: string){}

    @action.bound
    async getLiveLocationResults(location: string){
        this.isLoading = true;
        this.hasLocationResults = false;
        return await api.get('geolocationtest/geo/' + location)
        .then(result => {
            this.isLoading = false;
            this.hasLocationResults = true;
            return result;

        })
        .then(res => {
            this.hasLocationResults = true;
            this.searchResults = res['data'];
        })
        .catch(error => {
            console.log('live search location results error: ' + error);
        })

    }
}

const candidateSearchStore = new CandidateSearchStore();
export default candidateSearchStore;