import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';

import BusinessDashboard from './Dashboard';


class BusinessApp extends Component {
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path="/" component={BusinessDashboard} />
                    <Route path="/dashboard" component={BusinessDashboard} />
                </Switch>
            </React.Fragment>
        )
    }
}

export default BusinessApp