import React, { Component } from 'react';

class NotFound extends Component {
    render() {
        return(
            <><h1>Page Not Found</h1></>
        );
    }
}

export default NotFound