import React, { Component } from 'react';

import CandidateBasicInfoStore from '../Store';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { inject, observer } from 'mobx-react';

interface IProps {}

inject('CandidateBasicInfoStore'); // Basic Profile info store
@observer
class CandidateProfileBasicInfoEdit extends Component<IProps, any> {
    candidateBasicInfoStore = CandidateBasicInfoStore

    constructor(props: any){
        super(props);
        this.state = { open: false};
        this.candidateBasicInfoStore.getBasicInfoRecord();
    }

    render(){

        const { basicInfoRecord } = this.candidateBasicInfoStore;

        return(
          <div>
          <Button variant="outlined" color="primary" onClick={() => this.setState({ open: true})}>
            Edit
          </Button>
          <Dialog open={this.state.open} onClose={() => this.setState({ open: false})} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Basic Profile Info</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Update your name and location details below
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="firstName"
                label="First Name"
                type="firstName"
                defaultValue={basicInfoRecord.firstName}
                fullWidth
              />
              <TextField
                margin="dense"
                id="lastName"
                label="Last Name"
                type="lastName"
                defaultValue={basicInfoRecord.lastName}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ open: false})} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.setState({ open: false})} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        )
    }

}

export default CandidateProfileBasicInfoEdit;