import { TextField, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import { Helmet } from 'react-helmet';
import styles from './style';

import Nav from 'react-bootstrap/Nav';
import ShowCaseSectionSearch from '../../../Components/Public/Search/ShowCaseSectionSearch';
import RecentListingsCard from '../../../Components/Public/Card/RecentListings';
import testImageOne from '../../../Assets/DevImages/pexels-elevate-1267324.jpg';
import testImageTwo from '../../../Assets/DevImages/pexels-tiger-lily-4483862.jpg';
import testImageThree from '../../../Assets/DevImages/pexels-tima-miroshnichenko-6169132.jpg';

import amazonLogo from '../../../Assets/DevImages/amazon-test-logo-a.jpeg';
import dhlLogo from '../../../Assets/DevImages/dhl-test-logo.jpeg';

import JobsStore from '../Jobs/Store';
import { Container, Card, Grid, Avatar, CardMedia, Typography, Divider } from '@material-ui/core';

interface IProps extends WithStyles<typeof styles> {
    jobsStore: typeof JobsStore;
}

inject('jobsStore')
@observer
class HomePage extends Component<IProps> {
    jobsStore;
    
    constructor(props: any){
        super(props);
        this.jobsStore = JobsStore;
        this.jobsStore.getRecentListings();
    }


    render() {

        const { classes } = this.props;
        const { listings } = this.jobsStore;
        // businessName: string, location: string, jobTitle: string, wage: string

        const listItems = listings.map((listing: any) =>
                <Card key={listing.id}>
            <div className="top">
                <Typography>{listing.title}</Typography>
                <Typography>DHL Belfast</Typography>
            </div>
            <Divider />
            <div className="mid">
                <Typography>Belfast, United Kingdom</Typography>
            </div>
            <Divider />
            <div className="btm">
                <Typography>£20ph</Typography>
            </div>
        </Card>
                );

        return(
            <>
                <Helmet>
                    <title>Trickell Hire | Welcome</title>
                </Helmet>
                <BodyClassName className="Home" />
                <div className={classes.container}>
                    <section className={classes.sectionMain}>
                        <Container>
                            <div className={classes.showCase}>
                                <div className={classes.showCaseTextHold}>
                                    <Typography variant="h2"><b className={classes.showCaseTextBold}>HIRE</b> or <b className={classes.showCaseTextBold}>GET HIRED</b></Typography>
                                    <Typography className={classes.showCaseTextSub} variant="h4">The online Marketplace for businesses and professionals in the Wha industry</Typography>
                                </div>
                                <div className={classes.showCaseSearchHold}>
                                    <div className="searchWeb">
                                        <ShowCaseSectionSearch />
                                    </div>
                                    <div className="searchMobile"></div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className={classes.sectionMain}>
                        <Container>
                            <div className="recentListingsHold">
                                <div className={classes.recentListingsTop}>
                                    <Typography className={classes.recentListingsTopText}>Recently Listed</Typography>
                                </div>
                                <div className="mid">
                                    <div className="recentListingsWeb">
                                        <ul className={classes.recentListingsUl}>
                                            <li className={classes.recentListingsLi}>
                                                <Nav.Link className={classes.recentListingCardLiNavLink} href="/jobs">
                                                <Card className={classes.recentListingCard}>
                                                    <CardMedia className={classes.media} image={testImageOne} title="Job: Forklift Driver" />
                                                    <Typography className={classes.businessTitle}>Forklift Driver</Typography>
                                                    <Typography className={classes.recentListingsCardLiTextLight}>Belfast, United Kingdom</Typography>
                                                    <Typography className={classes.recentListingsCardLiTextMoney}>£20 Per Hour</Typography>
                                                </Card>
                                                </Nav.Link>
                                            </li>
                                            <li className={classes.recentListingsLi}>
                                            <Nav.Link className={classes.recentListingCardLiNavLink} href="/jobs">
                                            <Card className={classes.recentListingCard}>
                                                    <CardMedia className={classes.media} image={testImageTwo} title="Job: Warehouse Operative"/>
                                                    <Typography className={classes.businessTitle}>Warehouse Operative</Typography>
                                                    <Typography className={classes.recentListingsCardLiTextLight}>Derry, United Kingdom</Typography>
                                                    <Typography className={classes.recentListingsCardLiTextMoney}>£18 Per Hour</Typography>
                                                </Card>
                                            </Nav.Link>
                                            </li>
                                            <li className={classes.recentListingsLi}>
                                            <Nav.Link className={classes.recentListingCardLiNavLink} href="/jobs">
                                            <Card className={classes.recentListingCard}>
                                                    <CardMedia className={classes.media} image={testImageThree} title="Job: Delivery Driver" />
                                                    <Typography className={classes.businessTitle}>Delivery Driver</Typography>
                                                    <Typography className={classes.recentListingsCardLiTextLight}>Belfast, United Kingdom</Typography>
                                                    <Typography className={classes.recentListingsCardLiTextMoney}>£15 Per Hour</Typography>
                                                </Card>
                                            </Nav.Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="recentListingsMobile"></div>
                                </div>
                                <div className={classes.recentListingsHoldBtm}>
                                    <div className={classes.recentListingsHoldBtmLink}>
                                        <Typography className={classes.recentListingsHoldBtmLinkText}>View More</Typography>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className={classes.sectionMainColoured}>
                        <Container>
                            <div className="b_top">quick about info for contractors</div>
                            <div className="b_mid">quick about info for businesses</div>
                        </Container>
                    </section>
                </div>
            </>
        )
    }
}

export default withStyles(styles)(HomePage)