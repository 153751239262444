import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

interface IProps {
    variant: any,
    text: string,
    type: any
}

export default class SubmitButton extends Component<IProps> {
    render() {

        const { type, variant, text } = this.props;

        return(
            <Button type={type} variant={variant}>{text}</Button>
        )
    }
}

