import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import Helmet from 'react-helmet';

import styles from './styles';

import AuthStore from '../../../../Utils/AuthTestState';
import { withStyles, WithStyles } from '@material-ui/core/';

interface IProps extends WithStyles<typeof styles>{
    authStore?: typeof AuthStore;
}

inject('AuthStore')
@observer
class CandidateDashboard extends Component<IProps> {
    authStore = AuthStore;
    render() {
        const { classes } = this.props;

        return(
            <div className={classes.container}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Trickell Hire | Dashboard</title>
                </Helmet>
                <p className="tklCandidateDashboard">This is the candidate dashboard</p>
            </div>
        )
    }
}

export default withStyles(styles)(CandidateDashboard)