import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import {useStyles} from './style';

function ShowCaseSectionSearch(){
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState('');
    const [checkHasLocationSet, setCheckHaslocation] = useState(false);
    const [searchLocation, setSearchLocation] = useState('');
    const [searchLocationLat, setSearchLocationLat] = useState('');
    const [searchLocationLng, setSearchLocationLng] = useState('');
    const [radius, setRadius] = useState('');

    const setTitle = (title: string) => {
        setSearchTerm(title);
    }

    const setLocation = (location: string, locationLat: any, locationLng: any) => {
        // Do some checks to make sure location, lat and lng are all set, otherwise ignore 
    }

    const setSearchRadius = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRadius(event.target.value as string);
    };

    // Final Job search Submit
    const _doJobSearch = () => {}

    // The main searchTitle or Job title input layout
    const searchTitleInputUi = (
        <FormControl>
            <TextField fullWidth={true} InputProps={{ disableUnderline: true }} className={classes.searchTitleInputWeb} placeholder="Job Title" />
        </FormControl>
    );

    // The main location input form layout
    const searchLocationInputUi = (
        <FormControl>
            <TextField fullWidth={true} InputProps={{ disableUnderline: true }} className={classes.searchTitleInputWeb} placeholder="City, Town or Area" />
        </FormControl>
    );

    // As you type, search results should appear and disappear here
    const searchLocationInputResultsLoadUi = (locationTerm: string) => {
        // Send request to autocomplete (google) to load results for LocationTerm
    }

    // When the locations appear and you click one, we set the location data for search here
    const searchLocationInputResultsSetLocationData = () => {} // searchTitleInputWeb

    const searchRadiusInputUi = (
        <FormControl className={classes.searchTitleInputWebRadius}>
                <InputLabel id="showcase-search-set-radius">Radius</InputLabel>
                <Select fullWidth={true} disableUnderline
                labelId="showcase-search-set-radius"
                id="radius-select"
                value={radius}
                inputProps={{
                    name: 'Radius',
                    id: 'showcase-search-set-radius',
                  }}
                onChange={setSearchRadius}
                >
                <MenuItem value={5}>5 Miles</MenuItem>
                <MenuItem value={10}>10 Miles</MenuItem>
                <MenuItem value={15}>15 Miles</MenuItem>
                <MenuItem value={20}>20 Miles</MenuItem>
                <MenuItem value={25}>25 Miles</MenuItem>
                <MenuItem value={30}>30 Miles</MenuItem>
                <MenuItem value={35}>35 Miles</MenuItem>
                <MenuItem value={40}>40 Miles</MenuItem>
                </Select>
          </FormControl>
    );

    return (
        <div className={classes.searchShowCaseContainer}>
            <div className={classes.searchUiWeb}>
                <div className={classes.searchUiWebContainer}>
                    <Grid container justify="center">
                        <Grid className={classes.gridSearchInput}>{searchTitleInputUi}</Grid>
                        <Grid className={classes.gridSearchInput}>{searchLocationInputUi}</Grid>
                        <Grid className={classes.gridSearchInput}>{searchRadiusInputUi}</Grid>
                        <Grid className={classes.gridSearchInput}>
                            <Button className={classes.searchIconButton} variant="contained" color="primary" component="span">
                                <SearchIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="searchUiMobile"></div>
        </div>
    );
}

export default ShowCaseSectionSearch;