import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import Api from '../../../../../Utils/Api';

class CandidateSettingsStore {
    @observable isLoading = false;
    @observable UserSettingsInfo = {first_name: '', last_name: '', email: ''};
    @observable candidateSettings: any[] | any = [];

    constructor(){
        makeObservable(this);
        autorun(() => {
            //this.loadUserSettingsInfo();
            //this.testCandidateRequest();
        });
    }

    @action.bound async loadUserSettingsInfo(){
        this.isLoading = true;

        return await Api.get('user')
        .then(result => {
            runInAction(() => {
                this.isLoading = false;
                this.UserSettingsInfo = result;
                this.candidateSettings = result;
            });
        })
        .catch(error => {
            this.isLoading = false;
        });
    }

    @action.bound async testCandidateRequest(){
        this.isLoading = true;

        return await Api.get('candidate/settings')
        .then(result => {
            runInAction(() => {
                this.isLoading = false;
                this.candidateSettings = result;
            });
        });
    }
}

const candidateSettingsStore = new CandidateSettingsStore();
export default candidateSettingsStore;

