import { Component } from 'react';
import TextField from '@material-ui/core/TextField';

interface IProps {
    type: string,
    name: string,
    label: string,
    defaultValue: string,
    placeholder: string,
    required: boolean,
    variant: any,
}

export default class Input extends Component<IProps> {
    render(){

        const { type, name, defaultValue, placeholder, required, label, variant} = this.props;

        // <input type={type} defaultValue={defaultValue} name={name} placeholder={placeholder} required={required} />

        return (
            <TextField name={name} type={type} placeholder={placeholder} label={label} defaultValue={defaultValue} variant={variant} required={required}/>
        )
    }
}