import TextField from '@material-ui/core/TextField';
import SubmitButton from '../../../../../Packages/Tkl-core-controls/Buttons/Submit';

export default function CandidateRegistrationForm() {

    // Logic to process candidate registration

    return(
        <div className="CandidateRegistrationForm">
            <form autoComplete="off">
                <div className="formControl">
                    <TextField required={true} label="First Name" variant="outlined" placeholder="First Name" type="text" />
                </div>
                <div className="formControl">
                    <TextField required={true} label="Last Name" variant="outlined" placeholder="Last Name" type="text" />
                </div>
                <div className="formControl">
                    <TextField required={true} label="Email" variant="outlined" placeholder="Email" type="email" />
                </div>
                <div className="formControl">
                    <TextField required={true} label="Password" variant="outlined" placeholder="Password" type="password" />
                </div>
                <div className="formControl">
                    <SubmitButton type="submit" variant="outlined" text="Register" />
                </div>
            </form>
        </div>
    )
}