import { makeObservable, observable, action, runInAction, autorun } from "mobx";
import api from "../../../../../../Utils/Api";

class CandidateWorkHistoryStore {
    @observable workHistory: any;
    @observable singleWorkHistoryRecord: any = {
        id: '',
        title: '',
        companyName: '',
        description: ''
    };
    @observable workHistoryCount: number = 0;
    @observable isLoading: boolean = false;
    @observable isAdding: boolean = false;
    @observable isDeleteing: boolean = false;
    @observable isError: boolean = false;
    @observable errorMessage: string = '';
    formRecordToDelete: any = {
        id: 0
    }
    @observable formSubmitNewWorkHistoryRecord: any = {
        jobTitle: '',
        jobCompany: '',
        jobIsCurrent: false,
        jobHasDescription: false,
        jobDescription: '',
        jobHasLocation: false,
        jobStartDate: '2016-06-03',
        jobEndDate: '2019-07-01',
        jobLocationCity: 'Berlin',
        jobLocationTown: 'Berlin',
        jobLocationCountry: 'Germany',
    }
    @observable formSubmitError: any = {
        titleError: '',
        companyError: '',
        descriptionError: ''
    }
    
    constructor(){
        makeObservable(this);
        autorun(() => {
            //this.getWorkHistory();
            //this.testGeoLocationResults();
        });
    }

    /**
     * A method to call and return the candidates work history from the API.
     * A check in the API is done by using the passed Bearer token/access token
     * to make sure that it belongs to the logged in user
     * 
     * @returns Promise
     */

    @action.bound
    async getWorkHistory(){
        this.isLoading = true;
        return await api.get('candidate/profile/workexperience')
        .then(result => {
            runInAction(() => {
                if(result == null){
                    this.isLoading = false;
                    this.workHistoryCount = 0;
                    this.errorMessage = "No work history added"
                } else {
                    this.isLoading = false;
                    this.workHistoryCount = 1;
                    this.workHistory = result;
                }
            });

        })
        .catch(error => {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error;
        })
    }

    /**
     * A method used to edit a candidates work history. Two props are required for this to work,
     * The workHistoryId and the workHistory record which has all the required fields/data
     * 
     * @param workHistoryId 
     * @param workHistoryRecord 
     */

    @action.bound
    async getSingleWorkHistoryRecord(workHistoryId: number){
        this.isLoading = false;
        this.singleWorkHistoryRecord = '';
        return await api.get('candidate/profile/getsingleworkexperience/' + workHistoryId)
        .then(result => {
            this.isLoading = false;
            runInAction(() => {
                this.singleWorkHistoryRecord = {
                    title: result.title,
                    companyName: result.company_name,
                    description: result.description
                };
            })
        })
        .catch(error => {
            this.isLoading = false;
        })
    }

    /**
     * Updating a candidate users work history record. In order to do this you need to 
     * pass an ID (the workhistory recrod ID) and an array of required values.
     * 
     * @param workHistoryId 
     * @param workHistoryRecord 
     * @returns 
     */

    @action.bound
    async updateSingleWorkHistoryRecord(workHistoryId: number, workHistoryRecord: any){
        this.isLoading = true;
        return await api.post('candidate/profile/updatecandidatenewsingleworkrecord', workHistoryRecord)
        .then(result => {
            runInAction(() => {
                this.isLoading = false;
                if(result === 'success') {
                    this.getWorkHistory();
                } else {
                    console.log('no success')
                }
            })
        })
        .catch(error => {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error;
        });
    }

    /**
     * A method used to add a candidates work history. You should never need to 
     * pass a user ID to update a record as we rely on the bearer token/access_token
     * to confirm that this can be updated on the API
     * 
     * @param workHistoryRecord 
     */

    @action.bound
    async addWorkHistory(workHistoryRecord: any){
        this.isLoading = true;
        return await api.post('candidate/profile/addcandidatenewsingleworkrecord', workHistoryRecord)
        .then(result => {
            runInAction(() => {
                this.isLoading = false;
                if(result === 'success') {
                    this.getWorkHistory();
                } else {
                    console.log('no success')
                }
            })
        })
        .catch(error => {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error;
        });
    }

    /**
     * A method used to delete a candidates work history. Requires a 
     * workHistoryId which is later checked against an access_token
     * on the api that it belongs to the user trying to delete it.
     * 
     * @param workHistoryId
     */

    @action.bound
    async deleteWorkHistory(workHistoryId: number){
        this.isLoading = true;
        return await api.post('candidate/profile/deletecandidatesingleworkrecord', workHistoryId)
        .then(result => {
            runInAction(() => {
                this.isLoading = false;
                if(result === 'Success') {
                    this.getWorkHistory();
                }
            })
        })
        .catch(error => {
            this.isLoading = false;
        });
    }

    setError(error: string){

    }

    @action.bound
    async testGeoLocationResults(){
        return await api.get('geolocationtest/geo')
        .then(result => {
        })
        .catch(error => {
            console.log('error on geo: ' + error)
        })
    }
}

const candidateWorkHistoryStore = new CandidateWorkHistoryStore();
export default candidateWorkHistoryStore;