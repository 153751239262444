import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CandidateEducationStore from '../Store';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

interface IProps {
    id: number
}

inject('CandidateEducationStore')
@observer
class CandidateProfileEditEducation extends Component<IProps, any> {
    candidateEducationStore = CandidateEducationStore;
    record: any;
    errors: any = {
        establishmentNameError:  ''
      };
    constructor(props: any){
        super(props);
        this.state = { open: false};
    }

    render() {

        const { singleEducationRecord, formSubmitNewEducationRecord, formSubmitError, formRecordToDelete} = this.candidateEducationStore;
        const handleOpenClick = async (id: number) => {

            // get the single work history record
            return await this.candidateEducationStore.getSingleEducationRecord(this.props.id)
            .then(result => {
                return result;
            })
            .then(res => {
                this.setState({ open: true});
                
            })
            .catch(error => {
                this.setState({ open: false});
            })
        }

        const setEstablishmentName = (title: string) => {
            if(title.length < 1 && singleEducationRecord.establishmentName.length > 0) {
              formSubmitError.establishmentNameError = '';
              formSubmitNewEducationRecord.establishmentName = singleEducationRecord.establishmentName;
  
            }
            else if(title.length < 1){
                formSubmitError.establishmentNameError = 'Please insert a College or University name';
                this.errors.establishmentNameError = 'Please insert a College or University name';
            }
            else {
                formSubmitError.establishmentNameError = '';
                formSubmitNewEducationRecord.establishmentName = title;
            }
        }

        const handleEducationUpdateSubmit = async () => {
            if(formSubmitError) {
                // disable update button
            }

            formSubmitNewEducationRecord.id = this.props.id;
            await this.candidateEducationStore.updateSingleEducationRecord(this.props.id, formSubmitNewEducationRecord);
      
            // Update state to close modal or set store isLoading to true
            this.setState({ open: false})
        }

        const handleRemoveClick = async () => {
            formRecordToDelete.id = this.props.id;

            return await this.candidateEducationStore.deleteEducation(formRecordToDelete);
        }

        const handleCloseClick = () => {
            this.setState({ open: false});
        }

        const dialogButton = (
            <IconButton aria-label="Edit" size="small" onClick={() => handleOpenClick(this.props.id)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
        );

        const dialogContent = (
            <Dialog open={this.state.open} onClose={() => handleCloseClick()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Edit your work history details below
              </DialogContentText>
              <form onSubmit={() => handleEducationUpdateSubmit}>
                <TextField margin="dense" id="title" label="College/University Name" type="text" placeholder="College/University Name" defaultValue={singleEducationRecord.title} onChange={e => setEstablishmentName(e.target.value)} fullWidth required />
                <p className="">{formSubmitError.establishmentNameError}</p>
              </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleRemoveClick} color="primary">
                Remove
              </Button>
              <Button onClick={() => this.setState({ open: false})} color="primary">
                Cancel
              </Button>
              <Button onClick={handleEducationUpdateSubmit} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>
        )

        return(
            <>
                {dialogButton}
                {dialogContent}
            </>
        );
    }
}

export default CandidateProfileEditEducation