import { Container } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import styles from './style';

import CandidateWorkHistoryStore from './Store';

import CandidateAddWorkHistory from './Add';
import CandidateEditWorkHistory from './Edit';

interface IProps extends WithStyles<typeof styles>{}

inject('CandidateWorkHistoryStore')
@observer
class CandidateProfileJobHistory extends Component<IProps> {
    candidateWorkHistoryStore = CandidateWorkHistoryStore;

    render() {

        const { classes } = this.props;

        const { isLoading, workHistoryCount, workHistory } = this.candidateWorkHistoryStore;

        if(isLoading){
            // show spinner
            return (<>still loading work history</>);
        }

        if(workHistoryCount < 1) {
            // Show Add work history component
            return (<CandidateAddWorkHistory />);
        }

        if(workHistoryCount > 0) {

            let history = workHistory.map((data: any) =>
                <div key={data.id} className={classes.jobHistoryItemContainer}>
                    <div className="">
                        <CandidateEditWorkHistory id={data.id} />
                    </div>
                    <div className="">
                        <div className="boom">{data.title}</div>
                        <div className="boom">{data.company_name}</div>
                        <div className="boom">{data.location_city} - {data.location_country}</div>
                        <div className="boom"><Moment format="MMM YYYY" date={data.start_date} /> - <Moment format="MMM YYYY" date={data.end_date} /> - 2 months</div>
                    </div>
                </div> 
            ) 

            return (
                <Container>
                    <div className="top"><h3>Work Experience</h3></div>
                    <div className="top"><CandidateAddWorkHistory /></div>
                    <div className="mid">{history}</div>
                    <div className="btm"></div>
                </Container>
            )
        }
    }
}

export default withStyles(styles)(CandidateProfileJobHistory)