import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import CandidateSkillsAndTagsStore from './Store';

import Chip from '@material-ui/core/Chip';
import AddTags from './Add';
import { Container } from '@material-ui/core';

interface IProps {}

inject('CandidateSkillsAndTagsStore')
@observer
class CandidateProfileSkillsAndTags extends Component<IProps> {
    candidateSkillsAndTagsStore = CandidateSkillsAndTagsStore;

    render() {
        const { skillsAndTags, tagCount } = this.candidateSkillsAndTagsStore;

        let content = {}

        if(tagCount < 1){
            content = <AddTags />
        }
        else {
            // Loop through skills tags
            content = skillsAndTags.map((item: any) => {
                return <Chip key={item.id} className="tag" data-tag-id={item.id} label={item.name}></Chip>
            });
        }

        return(
            <Container>
                <div className="top"><h3>Skills and Tags</h3></div>
                <div className="mid">{content}</div>
            </Container>
        )
    }
}

export default CandidateProfileSkillsAndTags;