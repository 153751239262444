import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../Packages/Tkl-core-ui-controls/Global/Theme/Palette';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'transparent',
  },
  '.MuiAppBar-colorPrimary': {
    backgroundColor: '#ffffff',
    background: '#ffffff',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  appBarSet: {
    backgroundColor: '#ffffff',
    background: '#ffffff',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  navLink: {
      color: 'black',
      textDecoration: 'none',
      textTransform: 'none',
  },
  navLinkWithColorText: {
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'none',
    textTransform: 'none',
  },
  ulMenu: {
      float: 'right',
      listStyle: 'none',
      display: 'inline-flex',
  },
  tklNavButtonAfix: {
    marginRight: '2px',
    '&:hover': {
      backgroundColor: 'none',
      marginRight: '2px',
    },
  },
  tklBlueBtn: {
    backgroundColor: '#28a7e9',
    marginRight: '2px',
    '&:hover': {
      backgroundColor: '#28a7e9',
      marginRight: '2px',
    },
  },
  tklGreenbtn: {
    backgroundColor: '#69cb95',
    '&:hover': {
      backgroundColor: '#69cb95',
    },
  },
  tklDesktopMobileMenuIcon: {},

  tklDesktopMenu: {
    display: 'block',
  },
  tklDesktopMobileMenu: {
    display: 'none',
    textAlign: 'right',
  },
  // Mobile Devices
  "@media (min-width: 320px)":{
    tklDesktopMenu: {
      display: "none",
    },
    tklDesktopMobileMenu: {
      display: 'block',
      textAlign: 'right',
    },
  },
  "@media (min-width: 360px)":{
    tklDesktopMenu: {
      display: "none",
    },
    tklDesktopMobileMenu: {
      display: 'block',
      textAlign: 'right',
    },
  },
  "@media (min-width: 480px)":{
    tklDesktopMenu: {
      display: "none",
    },
    tklDesktopMobileMenu: {
      display: 'block',
      textAlign: 'right',
    },
  },

  // IPad, Tablets
  "@media (min-width: 768px)":{
    tklDesktopMenu: {
      display: "none",
    },
    tklDesktopMobileMenu: {
      display: 'block',
      textAlign: 'right',
    },
  },

  // Small screens, Laptops
  "@media (min-width: 1024px)":{
    tklDesktopMenu: {
      display: "block",
    },
    tklDesktopMobileMenu: {
      display: 'none',
      textAlign: 'right',
    },
  },

  // Desktops, Large Screens
  "@media (min-width: 1200px)":{
    tklDesktopMenu: {
      display: "block",
    },
    tklDesktopMobileMenu: {
      display: 'none',
      textAlign: 'right',
    },
  },

  // Extra large screens
  "@media (min-width: 1201px)":{
    tklDesktopMenu: {
      display: "block",
    },
    tklDesktopMobileMenu: {
      display: 'none',
      textAlign: 'right',
    },
  },
});

export { useStyles };