import React, { Component } from 'react';
import Nav from 'react-bootstrap/esm/Nav';
import Helmet from 'react-helmet';

import CandidateRegistrationForm from '../../../../Components/Public/Forms/Register/CandidateRegistration';

import Style from './style';

interface IProps {
    classes: typeof Style
}

class CandidateRegister extends Component<IProps> {
    render() {

        return(
            <div className="CandidateRgister">
                <Helmet>
                    <title>Trickell Hire | Register</title>
                </Helmet>
                <div className="candidateRegistrationForm">
                    <CandidateRegistrationForm />
                </div>
                <div className="extraLinks">
                    <Nav.Link href="/login">Login to your Account</Nav.Link>
                </div>
            </div>
        )
    }
}

export default CandidateRegister