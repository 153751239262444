import { Container } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './style';

import CandidateProfileAddEducation from './Add';
import CandidateProfileEditEducation from './Edit';

import CandidateEducationStore from './Store';

interface IProps extends WithStyles<typeof styles>{}

inject('CandidateWorkHistoryStore')
@observer
class CandidateProfileEducation extends Component<IProps> {
    candidateEducationStore = CandidateEducationStore;

    render() {

        const { classes } = this.props;

        const { isLoading, educationCount, educationRecords } = this.candidateEducationStore;
        if(!isLoading && educationCount < 1) {
            return (
                <Container>
                    <div className="top"><h3>Education</h3></div>
                    <div className="mid"><CandidateProfileAddEducation /></div>
                </Container>
            );
        }

        if(educationCount > 0) {

            let education = educationRecords.map((data: any) =>
                <div key={data.id} className={classes.jobHistoryItemContainer}>
                    <div className="">
                        <CandidateProfileEditEducation id={data.id} />
                    </div>
                    <div className="">
                        <div className="boom">{data.establishment_name}</div>
                    </div>
                </div> 
            ) 

            return (
                <Container>
                    <div className="top"><h3>Education</h3></div>
                    <div className="top"><CandidateProfileAddEducation /></div>
                    <div className="mid">{education}</div>
                    <div className="btm"></div>
                </Container>
            )
        }
    }
}

export default withStyles(styles)(CandidateProfileEducation)