import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CandidateWorkHistoryStore from '../Store';

interface IProps {}

inject('CandidateWorkHistoryStore')
@observer
class CandidateProfileJobHistoryAdd extends Component<IProps, any> {
  candidateWorkHistoryStore = CandidateWorkHistoryStore;  
  
  title: string = '';
    jobCompany: string = '';
    jobDescription: string = '';
    errors: any = {
      titleError:  '',
      companyNameError: '',
      descriptionError: ''
    };
    
    constructor(props: any){
        super(props);
        this.state = { open: false};
    }

    render(){

      const { formSubmitError, formSubmitNewWorkHistoryRecord } = this.candidateWorkHistoryStore;

      const setTitle = (title: string) => {
        if(title.length < 1){
          console.log('title field needs data');
          formSubmitError.titleError = 'Please Insert a Job Title';
          this.errors.titleError = 'Please Insert a Job Title';
        }
        else {
          formSubmitError.titleError = '';
          formSubmitNewWorkHistoryRecord.jobTitle = title;
        }
      }
      const setJobCompany = (jobCompany: string) => {
        if(jobCompany.length < 1){
          this.errors.companyNameError = 'Please enter the name of the company';
        }
        else {
          this.errors.companyNameError = ''
          formSubmitNewWorkHistoryRecord.jobCompany = jobCompany;
        }
      }
      const setJobDescription = (jobDescription: string) => {
        if(jobDescription.length < 1){
        }
        else {
          this.errors.descriptionError = '';
          formSubmitNewWorkHistoryRecord.jobDescription = jobDescription;
        }
      }
      const handleWorkExperienceSubmit = async () => {

        await this.candidateWorkHistoryStore.addWorkHistory(formSubmitNewWorkHistoryRecord);
  
        // Update state to close modal or set store isLoading to true
        this.setState({ open: false})
  
      }

        const dialogButton = (
            <IconButton aria-label="Add Work Experience" size="small" onClick={() => this.setState({ open: true})}>
              <AddCircleIcon fontSize="inherit" />
            </IconButton>
        );

        const dialogContent = (
          <Dialog open={this.state.open} onClose={() => this.setState({ open: false})} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add your work history details below
              </DialogContentText>
              <form onSubmit={() => handleWorkExperienceSubmit}>
              <TextField margin="dense" id="title" label="Job Title/Role" onChange={e => setTitle(e.target.value)} type="text" placeholder="Job Title/Role" fullWidth required />
              <p className="">{formSubmitError.titleError}</p>
              <TextField margin="dense" id="companyName" label="Company Name" onChange={e => setJobCompany(e.target.value)} type="text" placeholder="Company Name" fullWidth required />
              <p className="">{this.errors?.companyNameError}</p>
              <TextField margin="dense" id="jobDuties" label="Job Description" onChange={e => setJobDescription(e.target.value)} type="text" placeholder="Enter your job description info here" fullWidth />
              <p className="">{this.errors?.descriptionError}</p>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ open: false})} color="primary">
                Cancel
              </Button>
              <Button onClick={handleWorkExperienceSubmit} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        )

        return (
            <div className="workExperienceAddHold">
                <div className="weahTop">{dialogButton}</div>
                <div className="weahMid">{dialogContent}</div>
            </div>
        );
    }
    
}

export default CandidateProfileJobHistoryAdd;