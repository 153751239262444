import { useState } from 'react';
import { withRouter } from "react-router-dom";
import authStore from '../../../../../Utils/AuthTestState';
import userStore from '../../../../../Utils/Stores/User';

import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import SubmitButton from '../../../../../Packages/Tkl-core-controls/Buttons/Submit';

function CandidateLoginForm() {
    const history = useHistory();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();

        const token = await authStore.login({email, password});

        if(token !== false ) {
          localStorage.setItem('token', token);
          await userStore.getUser().then(result => {});
          history.push({ pathname: 'app/dashboard', authStore: authStore});
        } else {
          history.push({ pathname: 'login'});
        }
        
      }

    return(
        <div className="tklLoginPage">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="formInput">
                <TextField type="email" name="email" label="Email Address" placeholder="Email Address" value={email} variant="outlined" onChange={e => setEmail(e.target.value)}/>
              </div>
              <div className="formInput">
                <TextField type="password" name="password" label="Password" placeholder="Password" value={password} variant="outlined" onChange={e => setPassword(e.target.value)}/>
              </div>
              <div className="formInput">
                <SubmitButton className="" type="submit" variant="outlined" text="Login" />
              </div>
            </form>
        </div>
      )
}

const candidateLoginForm = CandidateLoginForm;
export default withRouter(candidateLoginForm);