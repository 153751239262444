import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    formControl: {},
    searchShowCaseContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '100%',
        height: '200px',
    },
    searchUiWeb: {
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '50%',
    },
    searchUiWebContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        borderRadius: '35px',
    },
    searchTitleInputWeb: {
        padding: '10px',
        width: '100%',
    },
    searchTitleInputWebSelect: {
        width: '100%',
        padding: '0px',
    },
    searchTitleInputWebRadius: {
        width: '100%',
    },
    searchIconButton: {
        borderRadius: '50px',
        height: '100%',
        float: 'right',
        marginRight: '10px',
    },
    gridSearchInput: {
        width: '25%',
    },
  // Mobile Devices
  "@media (min-width: 320px)":{
  },
  "@media (min-width: 360px)":{
  },
  "@media (min-width: 480px)":{
  },

  // IPad, Tablets
  "@media (min-width: 768px)":{
  },

  // Small screens, Laptops
  "@media (min-width: 1024px)":{
  },

  // Desktops, Large Screens
  "@media (min-width: 1200px)":{
  },

  // Extra large screens
  "@media (min-width: 1201px)":{
  },
});

export { useStyles };