import { useState, useEffect} from 'react';
import { Provider } from 'mobx-react';
import { Redirect, withRouter, useHistory } from 'react-router-dom';
import './App.css';
import { Container } from '@material-ui/core';
import Loader from "react-loader-spinner";

import UnauthenticatedApp from './UnauthtenticatedApp';
import AuthenticatedApp from './AuthenticatedApp';
import authStore from './Utils/AuthTestState';
import userStore from './Utils/Stores/User';

function App() {
  const pathname = window.location.pathname;
  const [isLoading, setLoading] = useState(true)
  const [hasToken, setToken] = useState(false);
  const history = useHistory();

  useEffect(() => {
     if(localStorage.getItem('token')) {
       const userAuthedCheck = async () => {
          const res = await userStore.checkInit();
          setLoading(false);
          return res;
       }
       userAuthedCheck()
       .then(() => {
         setLoading(false);
        if(userStore.user.isAuthenticated) {
          setToken(true);
        }
      });
     } else {
      setLoading(false);
     }
  }, []);

  if(isLoading) {
    return <Container><Loader type="ThreeDots" color="#00BFFF" height={100} width={100} /></Container>
  } else {
    if(localStorage.getItem('token') || hasToken) {
      if(pathname === '' || pathname === '/') {
         return <Redirect push to={{ pathname: 'app/dashboard', authStore: authStore }} />
      } else {
        return  <Provider initial={authStore} userStore={userStore}>
                  <AuthenticatedApp history={history} userStore={userStore} authStore={authStore}>
                  </AuthenticatedApp>
                </Provider>
      }
    } else {
      return <UnauthenticatedApp></UnauthenticatedApp>
    }
  }
}

export default withRouter(App);
