import { Button, Container } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from "react";
import CandidateProfileAvailabilityStore from './Store';

interface IProps {}

inject('CandidateProfileAvailabilityStore');
@observer
class CandidateProfileAvailability extends Component<IProps> {
    candidateProfileAvailabilityStore = CandidateProfileAvailabilityStore;

    render() {

        const { isLoading, hasAvailability, availabilityRecord } = this.candidateProfileAvailabilityStore;

        if(isLoading) {
            return(<>Loading availability</>);
        }

        if(!isLoading && hasAvailability < 1){

            return (
                <Container>
                    <div className="top"><h3>Availability</h3></div>
                    <div className="mid"><Button>Add</Button></div>
                </Container>
            );
            
        } 
        if(!isLoading && hasAvailability > 0) {

            let availRecord = availabilityRecord.map((data: any) => {
                const days = []
                days.push(data.has_monday_availability === 1 ? <div key="1" className="">Monday: {data.monday_start} - {data.monday_end}</div> : <div className="">Monday: Not Availabile</div>)
                days.push(data.has_tuesday_availability === 1 ? <div key="2" className="">Tuesday: {data.tuesday_start} - {data.tuesday_end}</div> : <div className="">Tuesday: Not Availabile</div>)
                days.push(data.has_wednesday_availability === 1 ? <div key="3" className="">Wednesday: {data.wednesday_start} - {data.wednesday_end}</div> : <div className="">Wednesday: Not Availabile</div>)
                days.push(data.has_thursday_availability === 1 ? <div key="3" className="">Thursday: {data.thursday_start} - {data.thursday_end}</div> : <div className="">Thursday: Not Availabile</div>)
                days.push(data.has_friday_availability === 1 ? <div key="3" className="">Friday: {data.friday_start} - {data.friday_end}</div> : <div className="">Friday: Not Availabile</div>)
                days.push(data.has_saturday_availability === 1 ? <div key="3" className="">Saturday: {data.saturday_start} - {data.saturday_end}</div> : <div className="">Saturday: Not Availabile</div>)
                days.push(data.has_sunday_availability === 1 ? <div key="3" className="">Sunday: {data.sunday_start} - {data.sunday_end}</div> : <div className="">Sunday: Not Availabile</div>)
                

                return days
            });

            return (
                <Container>
                    <div className="top"><h3>Availability</h3></div>
                    <div className="mid">
                        {availRecord}
                    </div>
                </Container>
            );
        }
    }
}

export default CandidateProfileAvailability;