import { Component } from 'react';
import BodyClassName from 'react-body-classname';
import clsx from 'clsx';
import BaseStyles from './Assets/Private/App/BaseStyles';

import CandidateApp from './Pages/Private/Candidate';
import BusinessApp from './Pages/Private/Business';

import AuthStore from './Utils/AuthTestState';
import UserStore from './Utils/Stores/User';
import { inject, observer, Provider } from 'mobx-react';
import { withStyles, WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof BaseStyles> {
    authStore: typeof AuthStore,
    userStore: typeof UserStore,
    user: any
}

inject('authStore')
@observer
class AuthenticatedApp extends Component<IProps> {
    authStore;
    userStore;
    constructor(props: any) {
        super(props);
        this.authStore = AuthStore;
        this.userStore = UserStore;
        UserStore.getUser();
    }

    render() {

        const { classes } = this.props;
        const { initial } = this.props.authStore;

        let content = {};

        if(initial.isCandidate) {
            content = <CandidateApp></CandidateApp>
        } 
        else if(initial.isBusiness) {
            content = <BusinessApp></BusinessApp>
        } 
        else if(initial.isAdmin) {
            content = <CandidateApp></CandidateApp>
        }
        else {
            return false;
        }

        return (
            <Provider authStore={AuthStore} userStore={UserStore}>
                <BodyClassName className={clsx(classes.MuiSetAppContainer)}>
                    {content}
                </BodyClassName>
            </Provider>
        )
    }
}

export default withStyles(BaseStyles)(AuthenticatedApp)

