import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#28a7e9',
      light: '#6fd8ff',
      dark: '#0078b6'
    },
    secondary: {
      main: '#28a7e9',
      light: '#6fd8ff',
      dark: '#0078b6'
    }
  },
  typography: {}
});

export default theme;