import { makeObservable, observable, action, runInAction, autorun } from "mobx";
import api from "../../../../../../Utils/Api";

// CandidateBasicInfoStore
class CandidateSkillsAndTagsStore {
    @observable skillsAndTags: any;
    @observable newSkillsAndTags: any;
    @observable tagCount: number = 0;
    @observable isLoading: boolean = false;
    @observable isAdding: boolean = false;
    @observable isDeleteing: boolean = false;
    @observable isError: boolean = false;
    @observable errorMessage: string = '';
    
    constructor(){
        makeObservable(this);
        autorun(() => {});
    }

    @action.bound
    async getSkillsAndTags(){
        this.isLoading = true;
        return await api.get('candidate/profile/skillsandtags')
        .then(result => {
            runInAction(() => {
                if(result == null){
                    this.isLoading = false;
                    this.tagCount = 0;
                    this.errorMessage = "No Skills and tags added"
                } else {
                    this.isLoading = false;
                    this.tagCount = 1;
                    this.skillsAndTags = result;
                }
            });

        })
        .catch(error => {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error;
        })
    }

    @action.bound
    async addSkillsAndTag(tags: any){}

    @action.bound
    async deleteSkillAndTag(tagId: number){}
}

const skillsAndTagsStore = new CandidateSkillsAndTagsStore();
export default skillsAndTagsStore;