import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import JobStore from './Store';

interface IProps {
    jobStore: typeof JobStore
}

inject('jobStore')
@observer
class Jobs extends Component<IProps> {
    jobsStore; 

    constructor(props: any){
        super(props);
        this.jobsStore = JobStore;
    }

    render(){

        return(
            <div className="">This is a page that contains a list of jobs</div>
        )
    }
}

export default Jobs