import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CandidateWorkHistoryStore from '../Store';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

interface IProps {
    id: number
}

inject('CandidateWorkHistoryStore')
@observer
class CandidateProfileJobHistoryEdit extends Component<IProps, any> {
    candidateWorkHistoryStore = CandidateWorkHistoryStore;
    record: any;
    errors: any = {
        titleError:  '',
        companyNameError: '',
        descriptionError: ''
      };
    constructor(props: any){
        super(props);
        this.state = { open: false};
    }

    render(){

        const { formSubmitError, formSubmitNewWorkHistoryRecord, singleWorkHistoryRecord, formRecordToDelete } = this.candidateWorkHistoryStore;

        const handleOpenClick = async (id: number) => {
            return await this.candidateWorkHistoryStore.getSingleWorkHistoryRecord(this.props.id)
            .then(result => {
                return result;
            })
            .then(res => {
                this.setState({ open: true});
                
            })
            .catch(error => {
                this.setState({ open: false});
            })
        }

        const setTitle = (title: string) => {
          if(title.length < 1 && singleWorkHistoryRecord.title.length > 0) {
            formSubmitError.titleError = '';
            formSubmitNewWorkHistoryRecord.jobTitle = singleWorkHistoryRecord.title;

          }
          else if(title.length < 1){
              formSubmitError.titleError = 'Please Insert a Job Title';
              this.errors.titleError = 'Please Insert a Job Title';
          }
          else {
              formSubmitError.titleError = '';
              formSubmitNewWorkHistoryRecord.jobTitle = title;
          }
        }
        
        const setJobCompany = (jobCompany: string) => {
          if(jobCompany.length < 1 && singleWorkHistoryRecord.companyName.length > 0) {
            formSubmitError.companyError = '';
            formSubmitNewWorkHistoryRecord.jobCompany = singleWorkHistoryRecord.companyName;
          }
          else if(jobCompany.length < 1) {
            this.errors.companyNameError = 'Please enter the name of the company';
          }
          else {
              formSubmitError.companyError = '';
              formSubmitNewWorkHistoryRecord.jobCompany = jobCompany;
          }
        }
        
        const setJobDescription = (jobDescription: string) => {
          if(jobDescription.length < 1 && singleWorkHistoryRecord.description.length > 0) {
            formSubmitError.descriptionError = '';
            formSubmitNewWorkHistoryRecord.jobDescription = singleWorkHistoryRecord.description;
          }
          else if(jobDescription.length < 1) {
            this.errors.descriptionError = 'Please enter the name of the company';
          }
          else {
            formSubmitError.descriptionError = '';
            formSubmitNewWorkHistoryRecord.jobDescription = jobDescription;
          }  
          if(jobDescription.length < 1){
            }
            else {
              this.errors.descriptionError = '';
              formSubmitNewWorkHistoryRecord.jobDescription = jobDescription;
            }
        }
        
        const handleWorkExperienceSubmit = async () => {
            //e.preventDefault();
            if(formSubmitError) {
              // disable update button
            }

            formSubmitNewWorkHistoryRecord.id = this.props.id;
            await this.candidateWorkHistoryStore.updateSingleWorkHistoryRecord(this.props.id, formSubmitNewWorkHistoryRecord);
      
            // Update state to close modal or set store isLoading to true
            this.setState({ open: false})
      
        }

        //const handleUpdateClick = async (e: any) => {e.preventDefault();}

        const handleRemoveClick = async () => {
            formRecordToDelete.id = this.props.id;
            
            return await this.candidateWorkHistoryStore.deleteWorkHistory(formRecordToDelete);
        }

        const handleCloseClick = () => {
            this.setState({ open: false});
        }

        const dialogButton = (
            <IconButton aria-label="Edit" size="small" onClick={() => handleOpenClick(this.props.id)}>
                <EditIcon fontSize="inherit" />
            </IconButton>
        );

        const dialogContent = (
            <Dialog open={this.state.open} onClose={() => handleCloseClick()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Edit your work history details below
              </DialogContentText>
              <form onSubmit={() => handleWorkExperienceSubmit}>
                <TextField margin="dense" id="title" label="Job Title/Role" type="text" placeholder="Job Title/Role" defaultValue={singleWorkHistoryRecord.title} onChange={e => setTitle(e.target.value)} fullWidth required />
                <p className="">{formSubmitError.titleError}</p>
                <TextField margin="dense" id="companyName" label="Company Name" type="text" placeholder="Company Name" defaultValue={singleWorkHistoryRecord.companyName} onChange={e => setJobCompany(e.target.value)} fullWidth required />
                <TextField margin="dense" multiline id="jobDuties" label="Job Description" type="text" placeholder="Enter your job description info here" defaultValue={singleWorkHistoryRecord.description} onChange={e => setJobDescription(e.target.value)} fullWidth />
              </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleRemoveClick} color="primary">
                Remove
              </Button>
              <Button onClick={() => this.setState({ open: false})} color="primary">
                Cancel
              </Button>
              <Button onClick={handleWorkExperienceSubmit} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>
        )

        return (
            <>
                {dialogButton}
                {dialogContent}
            </>
        );
    }
    
}

export default CandidateProfileJobHistoryEdit;