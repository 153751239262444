const styles = (theme: any) => ({
    contained: {
        background: 'white'
    },
    jobHistoryItemContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
});

export default styles;