import { Component } from 'react';
import styles from './style';
import { TextField, WithStyles, withStyles, Container } from '@material-ui/core';
import Nav from 'react-bootstrap/esm/Nav';
import Helmet from 'react-helmet';
import CandidateLoginForm from '../../../Components/Public/Forms/Login/CandidateLoginForm';

interface IProps extends WithStyles<typeof styles> {}

class LoginPage extends Component<IProps> {

    render() {

        const { classes } = this.props;

        return(
            <>
                <Helmet>
                    <title>Trickell Hire | Login</title>
                </Helmet>
                <div className={classes.container}>
                    <Container>
                        <CandidateLoginForm />
                        <div className="extraLinks">
                            <Nav.Link href="/register">Create a new Account</Nav.Link>
                        </div>
                    </Container>
                </div>
            </>
        )
    }
}

export default withStyles(styles)(LoginPage);