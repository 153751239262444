import { inject, observer } from 'mobx-react';
import { Component } from "react";
import { WithStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import styles from './style';

import CandidateSearchStore from '../Store';

interface IProps extends WithStyles<typeof styles>{}

inject('CandidateSearchStore')
@observer
class CandidateJobSearchForm extends Component<IProps> {
    candidateSearchStore = CandidateSearchStore;
    locationResultsTest: any;

    render() {

        const { classes } = this.props;
        const { searchDetails, hasLocationResults, searchResults, isLoading } = this.candidateSearchStore;

        const style: any = {
            searchResultContainer: {
                position: 'absolute'
            },
            hiddenResultContainer: {
                display: 'none'
            }
        }

        let content;
        // latitude: 54.603533, longitude: -5.928807
        const setLocation = async (location: string) => {
            searchDetails.location = location;
            await this.candidateSearchStore.getLiveLocationResults(searchDetails.location);
        }

        if(!isLoading && Array.isArray(searchResults) && hasLocationResults) {
            content = searchResults.map((item: any) => {
                return <div key={item.id} className={classes.resultItemContainer} data-tag-id={item.id}>
                            <p className={classes.resultItem}>{item.name} - {item.country}</p>
                            <div className="locationMeta" data-locality-meta={item.name} data-country-meta={item.country} data-lat-meta={item.latitude} data-lng-meta={item.longitude} style={style.hiddenResultContainer}></div>
                        </div>
            });
        } 
        else {
            // console.log('no content jazz');
        };

        return(
            <div>
                <div className="SearchIcon"></div>
                <InputBase placeholder="Search" inputProps={{ 'aria-label': 'Search' }} onChange={e => setLocation(e.target.value)}></InputBase>
                <div className={classes.resultsContainer} style={style.searchResultContainer}>{content}</div>
            </div>
        )
    }
}

export default withStyles(styles)(CandidateJobSearchForm)

