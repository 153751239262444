

const styles = (theme: any) => ({
    contained: {
        background: 'white'
    },
    formControl: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    top: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    tMid: {
        paddingTop: '10px',
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    tBtm: {
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    mid: {
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    mMid: {
        paddingTop: '10px',
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    mBtm: {
        paddingLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
    }
});

export default styles;