const styles = (theme: any) => ({
    container: {
        background: 'white',
        marginTop: '70px',
    },
    appBarSet: {
        color: 'green',
        minHeight: '40px',
        maxHeight: '50px',
    },
    sectionMain: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    showCase: {
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    showCaseTextHold: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    showCaseTextBold: {
        color: '#28a7e9',
    },
    showCaseTextSub: {
        color: 'gray',
    },
    showCaseSearchHold: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    sectionMainColoured: {
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#f4f6f6',
        height: '800px',
    },
    recentListingsHoldMid: {},
    recentListingsHoldBtm: {
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '10px',
        flexGrow: 1,
    },
    recentListingsHoldBtmLink: {},
    recentListingsHoldBtmLinkText: {
        fontWeight: 600,
        fontSize: '22px',
        color: '#28a7e9',
    },
    recentListingsWeb: {
        width: '100%',
    },
    recentListingsTop: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    recentListingsTopText: {
        color: '#28a7e9',
        fontWeight: 600,
        fontSize: '22px',
    },
    recentListingsUl: {
        display: 'flex',
        listStyle: 'none',
        paddingRight: '5px',
        paddingLeft: '5px',
    },
    recentListingsLi: {
        listSyle: 'none',
        width: '33.3%',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    recentListingCardLiNavLink: {
        textDecoration: 'none',
    },
    recentListingCard: {
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avLogo: {
        width: '99px',
        height: '79px',
    },
    businessTitle: {
        paddingRight: '5px',
        paddingLeft: '5px',
        fontWeight: 600,
        color: '#28a7e9',
        fontSize: '22px'
    },
    jobDescription: {
        paddingRight: '5px',
        paddingLeft: '5px',
        fontWeight: 100,
        fontSize: '12px',
    },
    recentListingsCardLiText: {
        paddingRight: '5px',
        paddingLeft: '5px',
    },
    recentListingsCardLiTextMoney: {
        paddingRight: '5px',
        paddingLeft: '5px',
        paddingBottom: '10px',
        fontWeight: 600,
        fontSize: '13px',
    },
    recentListingsCardLiTextLight: {
        paddingRight: '5px',
        paddingLeft: '5px',
        color: 'gray',
        fontSize: '13px',
    },
});

export default styles;