import React, { Component } from 'react';
import { Container, withStyles, WithStyles } from '@material-ui/core';
import styles from './style';

import CandidateBasicInfoStore from '../Store';

// Edit/Add dialog component import
import AddEditDialog from '../Edit';
import { inject, observer } from 'mobx-react';

interface IProps extends WithStyles<typeof styles> {}

inject('CandidateBasicInfoStore');
@observer
class CandidateProfileBasicInfoDetails extends Component<IProps> {
    candidateBasicInfoStore = CandidateBasicInfoStore;

    render(){

        const { classes } = this.props;
        const { basicInfoRecord} = this.candidateBasicInfoStore;

        return(
            <Container className={classes.contained}>
                <div className="top"></div>
                <div className="mid">
                    <h3>{basicInfoRecord?.firstName} {basicInfoRecord?.lastName}</h3>
                </div>
                <div className="btm"><AddEditDialog /></div>
            </Container>
        )
    }

}

export default withStyles(styles)(CandidateProfileBasicInfoDetails);