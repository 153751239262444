
import { observable, action, runInAction, autorun } from "mobx";
import api from "../../../../../../Utils/Api";

class CandidateProfileAvailabilityStore {
    @observable availabilityRecord: any;
    @observable hasAvailability: number = 0;
    @observable isLoading: boolean = false;
    @observable isAdding: boolean = false;
    @observable isEditing: boolean = false;
    @observable isDeleteing: boolean = false;
    @observable isError: boolean = false;
    @observable errorMessage: string = '';
    
    constructor(){
        autorun(() => {
        });
    }

    @action.bound
    async getCandidateProfileAvailability(){
        return await api.get('candidate/profile/availability')
        .then(res => {
            runInAction(() => {
                if(res == null) {
                    this.isLoading = false;
                    this.hasAvailability = 0;
                } else {
                    this.isLoading = false;
                    this.availabilityRecord = res;
                    this.hasAvailability = 1;
                }
            })
        })
        .catch(error => {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error;
        })
    }

    @action.bound 
    async updateCandidateProfileAvailability(){}
}

const candidateProfileAvailabilityStore = new CandidateProfileAvailabilityStore();
export default candidateProfileAvailabilityStore;