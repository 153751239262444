import { makeObservable, observable, computed, action, runInAction } from "mobx";
import api from "../../../../../Utils/Api";

import CandidateProfileBasicInfoStore from '../BasicInfo/Store';
import CandidateProfileAvailabilityStore from '../Availability/Store';
import CandidateProfileJobHistoryStore from '../JobHistory/Store';
import CandidateProfileSkillsAndTagsStore from '../SkillsAndTags/Store';
import CandidateProfileEducationStore from '../Education/Store';

class CandidateRootProfileStore { 
    candidateProfileBasicInfoStore;
    candidateProfileAvailabilityStore;
    candidateProfileJobHistoryStore;
    candidateProfileSkillsAndTagsStore;
    candidateProfileEducationStore;
    @observable isLoadingCandidateProfile: boolean = true;

    constructor(){
        makeObservable(this);
        this.candidateProfileBasicInfoStore = CandidateProfileBasicInfoStore;
        this.candidateProfileAvailabilityStore = CandidateProfileAvailabilityStore;
        this.candidateProfileJobHistoryStore = CandidateProfileJobHistoryStore;
        this.candidateProfileSkillsAndTagsStore = CandidateProfileSkillsAndTagsStore;
        this.candidateProfileEducationStore = CandidateProfileEducationStore;
    }

    
    @computed get isBuilding() {
        return this.isLoadingCandidateProfile ? true : false;
    }

    @action.bound
    async buildCandidateProfileOld() {
        await Promise.all([
            this.candidateProfileBasicInfoStore.getBasicInfoRecord(),
            this.candidateProfileJobHistoryStore.getWorkHistory(),
            this.candidateProfileSkillsAndTagsStore.getSkillsAndTags(),
            this.candidateProfileAvailabilityStore.getCandidateProfileAvailability(),
            this.candidateProfileEducationStore.getEducation()
        ])
        .then(result => {
            runInAction(() => {
                this.isLoadingCandidateProfile = false;
            })
        })
        .catch(error => {
            this.isLoadingCandidateProfile = false;
        });
    }

    @action.bound
    async buildCandidateProfile() {
        return await api.get('candidate/profile/getprofile')
        .then(result => {
            runInAction(() => {
                this.isLoadingCandidateProfile = false;
                // Set Basic Info
                this.candidateProfileBasicInfoStore.basicInfoRecord = {
                    firstName: result['basicInfo'][0].first_name,
                    lastName: result['basicInfo'][0].last_name
                };

                // Set Job History details
                if(result['workExperience'] == null) {
                    this.candidateProfileJobHistoryStore.isLoading = false;
                    this.candidateProfileJobHistoryStore.workHistoryCount = 0;
                    this.candidateProfileJobHistoryStore.errorMessage = "No work history added";
                } else {
                    this.candidateProfileJobHistoryStore.isLoading = false;
                    this.candidateProfileJobHistoryStore.workHistoryCount = 1;
                    this.candidateProfileJobHistoryStore.workHistory = result['workExperience'];
                }

                // Set Candidate Education
                if(result['education'] == null) {
                    this.candidateProfileEducationStore.isLoading = false;
                    this.candidateProfileEducationStore.educationCount = 0;
                    this.candidateProfileEducationStore.errorMessage = "No Education History Added";
                } else {
                    this.candidateProfileEducationStore.isLoading = false;
                    this.candidateProfileEducationStore.educationCount = 1;
                    this.candidateProfileEducationStore.educationRecords = result['education'];
                }

                // Set Candidate Skills and Tags
                if(result['skillsAndTags'] == null) {
                    this.candidateProfileSkillsAndTagsStore.isLoading = false;
                    this.candidateProfileSkillsAndTagsStore.tagCount = 0;
                    this.candidateProfileSkillsAndTagsStore.errorMessage = "No Skills Tags Added";
                } else {
                    this.candidateProfileSkillsAndTagsStore.isLoading = false;
                    this.candidateProfileSkillsAndTagsStore.tagCount = 1;
                    this.candidateProfileSkillsAndTagsStore.skillsAndTags = result['skillsAndTags'];
                }

                // Set Candidate Availability
                if(result['availability'] == null) {
                    this.candidateProfileAvailabilityStore.isLoading = false;
                    this.candidateProfileAvailabilityStore.hasAvailability = 0;
                } else {
                    this.candidateProfileAvailabilityStore.isLoading = false;
                    this.candidateProfileAvailabilityStore.availabilityRecord = result['availability'];
                    this.candidateProfileAvailabilityStore.hasAvailability = 1;
                }
            });
        })
        .catch(error => {
            console.log('result get profile single 1 error: ' + JSON.stringify(error));
        })
    }
}

const candidateRootProfileStore = new CandidateRootProfileStore()
export default candidateRootProfileStore;