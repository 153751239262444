import { inject, observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import themePalette from './Packages/Tkl-core-ui-controls/Global/Theme/Palette';

import JobsStore from './Pages/Public/Jobs/Store';

import Header from './Components/Public/Header';

import HomePage from './Pages/Public/Home';
import Jobs from './Pages/Public/Jobs';
import Login from './Pages/Public/Login';
import CandidateRegister from './Pages/Public/Register/Candidate';
import NotFound from './Pages/Public/NotFound';
import { ThemeProvider } from '@material-ui/core';

interface IProps {
    jobsStore: typeof JobsStore
}

inject('jobsStore')
@observer
class UnauthenticatedApp extends Component<IProps> {
    jobsStore;

    constructor(props: any) {
        super(props);
        this.jobsStore = JobsStore;
    }

    render() {

        // Changes for the model to open and close

        return (
            <ThemeProvider theme={themePalette}>
                <Provider jobsStore={this.jobsStore}>
                <React.Fragment>
                <Header />
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/jobs" component={Jobs} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={CandidateRegister} />
                    <Route component={NotFound} />
                </Switch>
            </React.Fragment>
            </Provider>
            </ThemeProvider>
        )
    }
}

export default UnauthenticatedApp;