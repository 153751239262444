import React, { useState } from 'react';
import {useStyles} from './style';
import Nav from 'react-bootstrap/Nav';
import { AppBar, Toolbar, Typography, Button, Grid, styled, Paper, Menu, MenuItem, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function Header(data: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const _openMobileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const desktopMenu = {};

    const mobileMenu = {};

    const _closeMobileMenu = () => {}
    
    return(
        <>
            <AppBar classes={{root: classes.appBarSet}}>
                <Toolbar>
                    <Grid container spacing={2}>
                        <Grid xs>
                            <Button className={classes.tklNavButtonAfix}><Typography><Nav.Link className={classes.navLink} href="/">LOGO</Nav.Link></Typography></Button>
                        </Grid>
                        <Grid className={classes.tklDesktopMenu} xs>
                            <ul className={classes.ulMenu}>
                                <Button className={classes.tklNavButtonAfix}><li><Nav.Link className={classes.navLink} href="/jobs">Ops</Nav.Link></li></Button>
                                <Button className={classes.tklNavButtonAfix}><li><Nav.Link className={classes.navLink} href="/jobs">For Business</Nav.Link></li></Button>
                                <Button className={classes.tklNavButtonAfix}><li><Nav.Link className={classes.navLink} href="/jobs">For Professionals</Nav.Link></li></Button>
                                <Button className={classes.tklBlueBtn}><li><Nav.Link className={classes.navLinkWithColorText} href="/login">Login</Nav.Link></li></Button>
                                <Button className={classes.tklGreenbtn}><li><Nav.Link className={classes.navLinkWithColorText} href="/register">Register</Nav.Link></li></Button>
                            </ul>
                        </Grid>
                        <Grid className={classes.tklDesktopMobileMenu} xs>
                            <IconButton id="mobilePositionMenu" aria-controls="mobilePositionMenu" aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={_openMobileMenu}><MenuIcon /></IconButton>
                            <Menu id="mobilePositionMenu" anchorEl={anchorEl} open={open}>
                                <MenuItem><Nav.Link className={classes.navLink} href="/jobs">For Business</Nav.Link></MenuItem>
                                <MenuItem><Nav.Link className={classes.navLink} href="/jobs">For Professionals</Nav.Link></MenuItem>
                                <MenuItem><Nav.Link className={classes.navLink} href="/login">Login</Nav.Link></MenuItem>
                                <MenuItem><Nav.Link className={classes.navLink} href="/register">Register</Nav.Link></MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    )
}

const header = Header;
export default withRouter(header)