import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { Provider } from 'mobx-react';
import { CookiesProvider } from "react-cookie";
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import authStore from './Utils/AuthTestState';
import UserAuth from './Utils/Stores/User'; // utils/store/user

// Create an initial Store or something similar to pass on app load.
// Auth store should only be used within the authenticated app class component
ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <Provider initial={authStore.initial} user={UserAuth}>
        <App />
      </Provider>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
