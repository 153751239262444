import { makeObservable, observable, action, autorun } from "mobx";
import Api from "../../Api";
import AuthStore from '../../AuthTestState';

class UserStore {
    @observable user: any = {
        firstName: '',
        lastName: '',
        isCandidate: false,
        isBusiness: false,
        isAdmin: false,
        isAuthenticated: false 
    }
    @observable isLoading: boolean = false;
    @observable isError: boolean = false;
    @observable errorMessage: any;
    @observable isInAuthStore: boolean = false;
    authStore = AuthStore;

    constructor(){
        makeObservable(this);
        autorun(() => {
            //this.getUser();
            //this.checkInit();
        });
    }

    @action.bound
    async checkInit() {
        await Promise.all([
            this.getUser()
        ])
    }

    @action.bound
    async getUser() {
        this.isLoading = true;
        return await Api.get('user')
        .then(result => {
                this.user = {
                firstName: result['first_name'],
                lastName: result['last_name'],
                email: result['email'],
                isCandidate: (result['account_type'] === '1.0') ? true : false,
                isBusiness: (result['account_type'] === '2.0') ? true : false,
                isAdmin: (result['account_type'] === '3.0') ? true : false,
                isAuthenticated: true
            }
            this.authStore.initial = {
                isCandidate: (result['account_type'] === '1.0') ? true : false,
                isBusiness: (result['account_type'] === '2.0') ? true : false,
                isAdmin: (result['account_type'] === '3.0') ? true : false,
                isAuthenticated: true
            }
            this.isLoading = false;
        })
        .catch(error => {
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = JSON.stringify(error);
        });
    }
}

const userStore = new UserStore()
export default userStore;