import { makeObservable, observable, computed, action, runInAction, autorun } from "mobx";
import Api from '../Api';

class AuthStore {
    @observable initial = {
        isCandidate: false,
        isBusiness: false,
        isAdmin: false,
        isAuthenticated: false
    }
    @observable user = {
        userInfo: { 
            firstName: '', 
            lastName: '', 
            accountRole: '', 
            accountType: ''
        },
        isCandidate: false,
        isBusiness: false,
        isAdmin: false
    };

    @observable isAuthed: boolean = false;
    @observable hasAuthToken: boolean = false;
    @observable authorization: any;
    @observable access_token: any;
    @observable isError: boolean = false;
    @observable isloading: boolean = false;

    constructor(){
        makeObservable(this);
        // check and re-authenticated
        if(localStorage.getItem('token')){
            autorun(() => {
                this.user = {
                    userInfo: {
                        firstName: "",
                        lastName: "",
                        accountRole: "",
                        accountType: ""
                    },
                    isCandidate: false,
                    isBusiness: false,
                    isAdmin: false
                }
                this.getUser();
            });
        } else {
        }
    }

    @computed get userRecord() {
        return this.user;
    }

    @computed get isAuthenticated() {
        return this.authorization ? true : false
    }

    @computed get getToken() {
        if (this.authorization) {
          return this.authorization.match(/(?:Bearer\s+)?(\w+\.\w+\.\w+)/)[1]
        } else {
          return null
        }
    }

    @action login(credentials: object) {
        this.isloading = true;
        return Api.post('login', credentials)
        .then(result => {
            this.isloading = false;
            return result;
        })
        .then(body => {
            const token = body.access_token;
            this.isAuthed = true;

            this.setAccessToken(token);
            Api.updateHeaders(token)

            if(token == null) {
                return false;
            } else {
                return token;
            }
        })
        .catch(error => {
        });
    }

    @action setAccessToken(token: any) {
        this.access_token  = token;
        this.authorization = `Bearer ${token}`;
        localStorage.setItem('token', token);

    }

    @action refreshToken(){}

    /**
     * Method to return the basic user record
     * 
     * @returns 
     */
    
    @action.bound async getUser(){
        this.isloading = true;
        return await Api.get('user')
        .then(result => {
            runInAction(() => {
                this.isloading = false;

                this.initial = {
                    isCandidate: (result['account_type'] === '1.0') ? true : false,
                    isBusiness: (result['account_type'] === '2.0') ? true : false,
                    isAdmin: (result['account_type'] === '3.0') ? true : false,
                    isAuthenticated: true
                }

                let res = this.user = {
                    userInfo: {
                        firstName: result['first_name'],
                        lastName: result['last_name'],
                        accountRole: result['account_role'],
                        accountType: result['account_type']
                    },
                    isCandidate: (result['account_type'] === '1.0') ? true : false,
                    isBusiness: (result['account_type'] === '2.0') ? true : false,
                    isAdmin: (result['account_type'] === '3.0') ? true : false
                }
                return res;
            });
        })
        .catch(error => {
            this.isloading = false;
            this.isError = true;
        });
    }

    @action.bound logout() {
        // clear or reset everything
        return this.user = {
            userInfo: {
                firstName: '',
                lastName: '',
                accountRole: '',
                accountType: ''
            },
            isCandidate: false,
            isBusiness: false,
            isAdmin: false
        }
    }
}

const authStore = new AuthStore();
export default authStore;