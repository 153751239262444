import { observable, action } from "mobx";
import api from "../../../../../../Utils/Api";

class CandidateBasicInfoStore {
    @observable basicInfoRecord: any = {
        firstName: '',
        lastName: ''
    };
    @observable isLoading: boolean = false;
    @observable isEdit: boolean = false;
    @observable isAdding: boolean = false;
    @observable isError: boolean = false;
    @observable errorMessage: any;

    @action.bound
    async addBasicInfoRecord(){
        this.isLoading = true;
        this.isLoading = false;
    }

    @action.bound
    async getBasicInfoRecord(){
        this.isLoading = true;
        return await api.get('candidate/profile/basicinfo')
        .then(res => {
            return res;
        })
        .then(result => {
            this.isLoading = false;
            this.basicInfoRecord = {
                firstName: result[0]['first_name'],
                lastName: result[0]['last_name']
            };
        })
        .catch(error => {
            console.log('error: ' + error);
        })
    }

    @action.bound
    public async updateBasicInfoRecord(){
        this.isLoading = true;
        this.isLoading = false;
    }
}

const candidateBasicInfoStore = new CandidateBasicInfoStore()
export default candidateBasicInfoStore;

