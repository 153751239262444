const styles = (theme: any) => ({
    resultsContainer: {
        background: '#f6f9fc',
        display: 'block',
        zIndex: 999,
        border: 'thin solid lightgray'
    },
    resultItemContainer: {
        padding: '10px'
    },
    resultItem: {
        color: 'black'
    }
});

export default styles;