import { makeObservable, observable, action, runInAction, autorun } from "mobx";
import api from "../../../../../../Utils/Api";

class CandidateEducationStore {
    @observable educationRecords: any;
    @observable singleEducationRecord: any = {
        id: '',
        establishmentName: ''
    };
    @observable educationCount: number = 0;
    @observable isLoading: boolean = false;
    @observable isAdding: boolean = false;
    @observable isDeleteing: boolean = false;
    @observable isError: boolean = false;
    @observable errorMessage: string = '';
    formRecordToDelete: any = {
        id: 0
    }
    @observable formSubmitNewEducationRecord: any = {
        establishmentName: ''
    }
    @observable formSubmitError: any = {
        establishmentNameError: ''
    }
    
    constructor(){
        makeObservable(this);
        autorun(() => {});
    }

    /**
     * A method to call and return the candidates education from the API.
     * A check in the API is done by using the passed Bearer token/access token
     * to make sure that it belongs to the logged in user
     * 
     * @returns Promise
     */

    @action.bound
    async getEducation(){
        this.isLoading = true;
        return await api.get('candidate/profile/education')
        .then(result => {
            runInAction(() => {
                if(result == null){
                    this.isLoading = false;
                    this.educationCount = 0;
                    this.errorMessage = "No Education Records Found"
                } else {
                    this.isLoading = false;
                    this.educationCount = 1;
                    this.educationRecords = result;
                }
            });
        })
        .catch(error => {

        });
    }

    /**
     * A method used to edit a candidates work history. Two props are required for this to work,
     * The workHistoryId and the workHistory record which has all the required fields/data
     * 
     * @param educationId
     */

    @action.bound
    async getSingleEducationRecord(educationId: number){
        this.isLoading = false;
        this.singleEducationRecord = '';
        return await api.get('candidate/profile/getsingleeducation/' + educationId)
        .then(result => {
            this.isLoading = false;
            runInAction(() => {
                this.singleEducationRecord = {
                    title: result.establishment_name
                };
            })
        })
        .catch(error => {
            this.isLoading = false;
        })
    }

    /**
     * Updating a candidate users work history record. In order to do this you need to 
     * pass an ID (the workhistory recrod ID) and an array of required values.
     * 
     * @param educationId 
     * @param educationRecord 
     * @returns 
     */

    @action.bound
    async updateSingleEducationRecord(educationId: number, educationRecord: any){
    }

    /**
     * A method used to add a candidates single education record. You should never need to 
     * pass a user ID to update a record as we rely on the bearer token/access_token
     * to confirm that this can be updated on the API
     * 
     * @param educationRecord 
     */

    @action.bound
    async addEducation(educationRecord: any){
        this.isLoading = true;
        return await api.post('candidate/profile/addcandidatenewsingleeducationrecord', educationRecord)
        .then(result => {
            runInAction(() => {
                this.isLoading = false;
                if(result === 'success') {
                    this.getEducation();
                } else {
                    console.log('no success')
                }
            })
        })
        .catch(error => {
            console.log('add edu 3: ' + error);
            this.isLoading = false;
            this.isError = true;
            this.errorMessage = error;
        });
    }

    /**
     * A method used to delete a candidates single education record. Requires an 
     * educationId which is later checked against an access_token
     * on the api that it belongs to the user trying to delete it.
     * 
     * @param workHistoryId
     */

    @action.bound
    async deleteEducation(educationId: number){
        this.isLoading = true;
        return await api.post('candidate/profile/deletecandidatesingleeducationrecord', educationId)
        .then(result => {
            runInAction(() => {
                this.isLoading = false;
                if(result === 'Success') {
                    this.getEducation();
                }
            })
        })
        .catch(error => {
            this.isLoading = false;
        });
    }

    setError(error: string){

    }
}

const candidateEducationStore = new CandidateEducationStore();
export default candidateEducationStore;