import React, { Component } from 'react';

class BusinessDashboard extends Component {
    render(){
        return (
            <p className="businessDashboard">I am a business dashboard</p>
        )
    }
}

export default BusinessDashboard;