import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CandidateSkillsAndTagsStore from '../Store';

interface IProps {}

inject('CandidateSkillsAndTagsStore')
@observer
class CandidateProfileSkillsAndTagsAdd extends Component<IProps, any> {
    candidateSkillsAndTagsStore = CandidateSkillsAndTagsStore;

    constructor(props: any){
        super(props);
        this.state = { open: false};
    }

    render(){

        //const { skillsAndTags, newSkillsAndTags, tagCount, isLoading, isAdding, isDeleteing, isError, errorMessage } = this.candidateSkillsAndTagsStore;

        // Used to set the tag name and then add to array _handleNewTagAddToArray(tagName)
        const _setTag = (tagName: string) => {}

        // Used to add a tag to the initial array before final submission
        const _handleNewTagAddToArray = () => {}

        // When a user adds a tag to the array, we use this to display before final submission
        // const _displayTagsFromArray = () => {}

        // When a user clicks delete or the x icon it will remove that specific tag from the array
        // const _removeTagsFromArray = () => {}

        // Used to finally submit all new tags
        const _handleNewTagsSubmit = async () => {}

        const dialogButton = (
            <IconButton aria-label="Add Skills and Experience Tags" size="small" onClick={() => this.setState({ open: true})}>
              <AddCircleIcon fontSize="inherit" />
            </IconButton>
        );

        const dialogContent = (
            <Dialog open={this.state.open} onClose={() => this.setState({ open: false})} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add Skills and Tags
              </DialogContentText>
              <form onSubmit={() => _handleNewTagsSubmit}>
              <div className="">
                  <div className="">
                      <div className="left">
                      <TextField margin="dense" id="title" label="Skill/Tag Name" onChange={e => _setTag(e.target.value)} type="text" placeholder="Skill/Tag Name" fullWidth required />
                      </div>
                      <div className="right">
                        <Button onClick={_handleNewTagAddToArray} color="primary">
                            Add to List of Skills
                        </Button>
                      </div>
                  </div>
                  <div className="">Display Skills added to array befor final submission</div>
              </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ open: false})} color="primary">
                Cancel
              </Button>
              <Button onClick={_handleNewTagsSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        );

        return (
            <>
              <div className="">
                <div className="">{dialogButton}</div>
                <div className="">{dialogContent}</div>
              </div>
            </>
        )
    }
}

export default CandidateProfileSkillsAndTagsAdd;