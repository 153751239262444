const styles = (theme: any) => ({
    container: {
        background: 'white',
        marginTop: '70px',
    },
    appBarSet: {
        color: 'green',
        minHeight: '40px',
        maxHeight: '50px',
    },
    sectionMain: {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
});

export default styles;