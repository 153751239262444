import { Component } from 'react';
import Helmet from 'react-helmet';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ClipLoader from "react-spinners/ClipLoader";

import AuthStore from '../../../../Utils/AuthTestState';
import UserStore from '../../../../Utils/Stores/User';
import CandidateSettingsStore from './Stores/CandidateSettingsStore';
import TextField from '../../../../Packages/Tkl-core-controls/Forms/Input';
import styles from './style';
import { inject, observer } from 'mobx-react';

interface IProps extends WithStyles<typeof styles>{
    candidateSettingsStore: typeof CandidateSettingsStore
}

inject('CandidateSettingsStore', 'AuthStore', 'UserStore')
@observer
class CandidateSettings extends Component<IProps> {
    userStore = UserStore;
    authStore = AuthStore;

    render() {
        const { classes } = this.props;
        const { user, isLoading } = this.userStore;

        if(isLoading){
            return <ClipLoader />
        } else {
            return (
                <div className="candidateSettings">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Trickell Hire | Settings</title>
                    </Helmet>
                    <Container>
                        <section>
                        <Container className={classes.contained}>
                        <div className={classes.top}>
                        <div className={classes.tMid}>
                            <Typography variant="h5" component="h5">General Account Settings</Typography>
                        </div>
                        <Divider />
                        <div className={classes.tBtm}>
                            <div className={classes.formControl}>
                                <TextField type="text" label="First Name" variant="outlined" name="name" defaultValue={user.firstName} placeholder="First Name" required={true}/>
                            </div>
                            <div className={classes.formControl}>
                                <TextField type="text" label="Last Name" variant="outlined" name="name" defaultValue={user.lastName} placeholder="Last Name" required={true}/>
                            </div>
                            <div className={classes.formControl}>
                                <TextField type="text" label="Langauge" variant="outlined" name="language" defaultValue="English (UK)" placeholder="Language" required={true}/>
                            </div>
                            <div className={classes.formControl}>
                                <TextField type="text" label="Gender" variant="outlined" name="gender" defaultValue="Male" placeholder="Gender" required={true}/>
                            </div>
                        </div>
                    </div>
                    </Container>
                        </section>
                    <div className={classes.mid}>
                        <div className={classes.mMid}>
                            <Typography variant="h5" component="h5">Login</Typography>
                        </div>
                        <Divider />
                        <div className={classes.mBtm}>
                            <div className={classes.formControl}>
                                <TextField type="email" label="Email Address" variant="outlined" name="name" defaultValue={user.email} placeholder="Email Address" required={true}/>
                            </div>
                        </div>
                    </div>
                    </Container>
                </div>
            )
        }
    }

    async onLoad(): Promise<void> {
        await CandidateSettingsStore.loadUserSettingsInfo();
    }
}

export default withStyles(styles)(CandidateSettings);