class Api {
    
    unauthenticatedHeaders = {};
    authenticatedHeaders   = {};
    headers = {};
    url = process.env.REACT_APP_PRODUCTION_API;

    constructor() {
        if (process.env.REACT_APP_ENVIRONMENT === 'local') {
            this.url = process.env.REACT_APP_LOCAL_API;
        }
        
        if(process.env.REACT_APP_ENVIRONMENT === 'production'){
            this.url = process.env.REACT_APP_PRODUCTION_API;
        }
        
        // Headers
        if(!localStorage.getItem('token')) {
            this.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
        }

        if(localStorage.getItem('token')) {
            this.headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token') 
            }
        }
    }

    updateHeaders(token: any = null) {
        // Set new headers
        if(!localStorage.getItem('token') && token === null) {
            this.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
        }

        if(localStorage.getItem('token') && token != null) {
            this.headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    }

    /**
     * Method used to make a GET request. Using JS in 
     * built fetch library to handle all requests.
     * 
     * @param {*} path 
     * @param {*} params 
     * @returns 
     */
    
    get(path: string, params: any = {}) {
        return fetch(this.url + path, {
            method: 'GET',
            headers: this.headers,
        })
        .then(data => data.json())
        .then(result => {
            return result;
        });
    }

    post(path: string, params: any) {
        return fetch(this.url + path, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
        .then(data => data.json())
        .then(result => {
            return result;
        });
    }

    put() {}

    delete() {}
}

const api = new Api();
export default api