import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import Api from '../../../../Utils/Api';

class JobsStore {
    @observable listings: any[] | any = [];
    @observable isLoading: boolean = false;
    @observable isError: boolean = false;

    constructor(){
        makeObservable(this);
        autorun(() => {
            //this.getRecentListings();
        });
        
    }

    /**
     * Get Listings.
     * A method used to list all job listings on the 
     * Unauthenticated/Public app. If you click the jobs/
     * listings tab, it will list all recent items
     * 
     * @returns 
     */

    @action getListings(){
        return Api.get('listings')
        .then((result) => {
        })
        .catch((error) => {
        });
    }

    /**
     * Get recent listings.
     * As this method is also called on the public homepage,
     * by default, the num is set to 4 because we can only display
     * 4 recent listings on the home page showcase section (the top).
     * You can change the number if you want to display more
     * 
     * @param num 
     */

    @action.bound async getRecentListings(num: number = 4) {
        this.isLoading = true;
        return await Api.get('recentlistings')
        .then(result => {
            this.isLoading = false;
            runInAction(() => {
                this.listings = result;
            });
        });
    }

    /**
     * Method used to get the listings for a specific category
     * 
     * @param categoryId 
     */

    @action getListingsByCategory(categoryId: number){}

    /**
     * A method used for searching through job listings on the
     * unauthenticated part of the application
     * 
     * @param title 
     * @param location 
     * @param distance 
     */
    
    @action getListingBySearchResult(title: String, location: string, distance: number){}

    /**
     * Dummy method to make sure the Store is working
     * 
     * @returns 
     */

    @action dummyMethod(){
        return true;
    }
}

const jobStore = new JobsStore();
export default jobStore;