import { inject, observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthStore from '../../../Utils/AuthTestState';
import UserStore from '../../../Utils/Stores/User';

import SearchAppBar from '../../../Packages/Tkl-core-ui-controls/Private/Candidate/Header';

import NotFound from './NotFound';
import CandidateDashboard from './Dashboard';
import CandidateProfile from './Profile';
import CandidateSettings from './Settings';
import { Container } from '@material-ui/core';

interface IProps {}

inject('authStore', 'userStore')
@observer
class CandidateApp extends Component<IProps> {
    userStore = UserStore;
    authStore = AuthStore;

    render() {

        return(
            <Provider authStore={AuthStore} user={UserStore}>
                <React.Fragment>
                <SearchAppBar />
                <Container>
                <Switch>
                    <Route exact path="/app/" component={CandidateDashboard} />
                    <Route path="/app/dashboard" component={CandidateDashboard} />
                    <Route path="/app/profile" component={CandidateProfile} />
                    <Route path="/app/settings" component={CandidateSettings} />
                    <Route component={NotFound} />
                </Switch>
                </Container>
            </React.Fragment>
            </Provider>
        );
    }
    
}

export default CandidateApp
